$(document).ready(function(){
  setSlickToday()
})
function setSlickToday(){
  $(".today-history-list-container").slick({
    speed: 300,
    slidesToShow: 1,
    infinite: false,
    variableWidth: true,
    lazyLoad: 'ondemand'
  });
  $('.slick-prev').html("")
  $('.slick-next').html("")
  $('.slick-dots button').html("")
  $('.today-history-desc-set-ellipsis').addClass('block-ellipsis')
  $('.home-today-history-container').removeClass('no-overflow')
}
$('.today-history-click').on('click', function(){
  if($('.today-list-popup'+$(this).data('id')+' .today-history-desc').text() != ""){
    $('.today-history-detail-popup').hide()
    $('.today-list-popup'+$(this).data('id')).show()
  }
})
$('.today-history-close').on('click', function(){
  $('.today-history-detail-popup').hide()
})